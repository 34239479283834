import React from 'react';
import { Box } from '@spothero/ui';
import { SearchPageView } from 'utils/search-page-view';
import SearchMapComponent from 'common/search-results-map/search-map';
import { useReduxSearchState } from 'pages/search/hooks/use-redux-search-state';
import { useOnShowDetailsClick } from 'pages/search/hooks/use-on-show-details-click';
export const SearchMap = ({ loadSpots }) => {
    const { spotsV2: spots, view } = useReduxSearchState();
    const onShowDetailsClick = useOnShowDetailsClick();
    return (<Box display={view === SearchPageView.MOBILE_MAP ? 'block' : 'none'} data-testid="SearchMap" position="relative" height="100%">
            <SearchMapComponent 
    // @ts-expect-error Typing on default export of enhance(SearchMap) is incorrect
    spots={spots} loadSpots={loadSpots} onShowDetailsClick={onShowDetailsClick}/>
        </Box>);
};
