import React, { useEffect } from 'react';
import { Flex, Box } from '@spothero/ui';
import { SearchComponentContainer } from './components/search-component/SearchComponentContainer';
import { useFetchSearchData } from 'pages/search/hooks/use-fetch-search-data';
import { SearchMap } from 'pages/search/components/SearchMap';
import { SpotList } from 'pages/search/components/SpotList';
import { SpotDetails } from 'pages/search/components/SpotDetails';
import classNames from 'classnames';
import { useReduxSearchState } from 'pages/search/hooks/use-redux-search-state';
import { SearchPageView } from 'utils/search-page-view';
import { useSpotIdSync } from 'pages/search/hooks/use-sync-spotid';
import { useAdjustRootPadding } from 'pages/search/hooks/use-adjust-root-padding';
import WithOptimizelySortByRelevance from 'pages/search-transient-event-monthly/WithOptimizelySortByRelevance';
import PriceBreakdownModal from 'common/price-breakdown-modal';
import { useSearchGtmTracking } from 'pages/search/hooks/use-search-gtm-tracking';
import { useHeaderSync } from 'pages/search/hooks/use-header-sync';
import { useHandlePastTimes } from 'pages/search/hooks/use-handle-past-times';
import SearchUtils from 'utils/search-utils';
import { useSegmentPageLanding } from 'pages/search/hooks/use-segment-page-landing';
const spotDetailsMobileListStyles = {
    top: '52px',
    height: '100%',
};
const _ResponsiveSearch = () => {
    const { loadSpots, loadMoreSpots } = useFetchSearchData();
    const { parkingType, view, selectedSpotId, priceBreakdownModalSpotId, priceBreakdownModalSpotRate, } = useReduxSearchState();
    const showPriceBreakdownModal = priceBreakdownModalSpotId || priceBreakdownModalSpotRate;
    const isSpotDetailsVisibleOnMobileList = selectedSpotId && view === SearchPageView.MOBILE_LIST;
    const searchClasses = classNames('Search', `parking-type-${parkingType}`, {
        'Search-list-view': view === SearchPageView.MOBILE_LIST ||
            view === SearchPageView.DESKTOP_LIST,
    }, {
        'Search-map-view': view === SearchPageView.MOBILE_MAP,
    });
    // Adjust padding on root element based on view
    useAdjustRootPadding(view);
    useSpotIdSync();
    useSearchGtmTracking();
    useSegmentPageLanding();
    useHeaderSync();
    useHandlePastTimes();
    // https://github.com/spothero/consumer-web/blob/b64eec953b71d05dbf432e269a2a8212e25b856e/src/js/pages/search-transient-event-monthly/WithSearch.jsx#L169
    useEffect(() => {
        SearchUtils.setPreviousSearch();
    }, []);
    return (<Box data-testid="ResponsiveSearch" position="absolute" top="0" left="0" right="0" bottom="0" overflow="hidden">
            <Flex flexFlow="column" overflow="hidden" position="relative" height="100%" sx={isSpotDetailsVisibleOnMobileList
            ? spotDetailsMobileListStyles
            : {
                '& .SpotMap': {
                    height: 'auto',
                    bottom: '0',
                    left: '0',
                    right: '0',
                    top: '0',
                    display: 'block',
                },
                '& .SearchMap': {
                    position: 'relative',
                    height: '100%',
                },
            }} className={searchClasses}>
                <SearchComponentContainer />
                <SearchMap loadSpots={loadSpots}/>
                <SpotList loadMoreSpots={loadMoreSpots}/>
                {Boolean(selectedSpotId) && <SpotDetails />}
                {showPriceBreakdownModal && (<PriceBreakdownModal spotId={priceBreakdownModalSpotId} rate={priceBreakdownModalSpotRate}/>)}
            </Flex>
        </Box>);
};
export const ResponsiveSearch = WithOptimizelySortByRelevance(_ResponsiveSearch);
